import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Theme from "../components/theme"
import SEO from "../components/seo"
import Footer from "../components/footer"
import GlobalStyles from "../components/global"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || `Landing Page`

  return (
    <Theme>
      <GlobalStyles />
      <SEO title={siteTitle} />
      <main>{children}</main>
      <Footer>
        © {new Date().getFullYear()} {siteTitle}. All rights reserved.
      </Footer>
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
