import React from "react"
import Carousel from "react-multi-carousel"
import styled from "styled-components"
import { Text } from "./type"

const H = styled(Text)`
  color: ${({ theme }) => theme.light};
`

const T = styled(Text)`
  color: ${({ theme }) => theme.light};
`

const DollaBillzYall = styled(Text)`
  color: ${({ theme }) => theme.accent};
  font-family: ${({ theme }) => theme.heading};
  font-size: 1.5rem;
  justify-self: flex-end;
`

const Slide = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.light};
  height: 216px;
  padding: 2.5rem 0.25rem;
  margin: 0 0.5rem;
  cursor: grab;

  &:active {
    cursor: grabbing;
    user-select: none;
  }

  ${H}, ${T}, ${DollaBillzYall} {
    text-align: center;
  }

  ${H}, ${DollaBillzYall} {
    font-weight: bold;
  }

  * {
    padding-bottom: 0.375rem;
  }
`

export default () => {
  if (typeof window === "undefined") {
    return null
  }

  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      renderButtonGroupOutside={true}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 4,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <Slide>
        <H>Maldonado vs. Ranger Construction Company</H>
        <T>Personal representative and referring attorney</T>
        <DollaBillzYall>$57,000,000</DollaBillzYall>
      </Slide>
      <Slide>
        <H>Slimbock vs. Walls/Alliance</H>
        <T>Personal Injury</T>
        <DollaBillzYall>$1,400,000</DollaBillzYall>
      </Slide>
      <Slide>
        <H>Esser vs. Provident Life Insurance</H>
        <T>Personal Injury</T>
        <DollaBillzYall>$1,350,000</DollaBillzYall>
      </Slide>
      <Slide>
        <H>Avery vs. Rowland</H>
        <T>Personal Injury</T>
        <DollaBillzYall>$1,050,000</DollaBillzYall>
      </Slide>
    </Carousel>
  )
}
