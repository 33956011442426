import styled from "styled-components"

const Grid = styled.div``

const Column = styled.div`
  flex: ${({ $size }) => ($size ? $size : 1)} 100%;
  min-height: 100%;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  flex-flow: ${({ flow }) => (flow ? flow : "column")};
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  align-items: ${({ align }) => (align ? align : "stretch")};
  background: ${({ theme, $bg }) => theme[$bg]};
  padding: 1rem 1.5rem 0;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-flow: ${({ flow }) => (flow ? flow : "column")};
  flex: ${({ $size }) => ($size ? $size : 1)} 100%;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  background: ${({ theme, $bg }) => theme[$bg]};
  align-items: ${({ align }) => (align ? align : "stretch")};
  @media (min-width: ${({ theme }) => theme.md}) {
    flex-direction: row;
  }
`

const Clamp = styled.div`
  max-width: ${({ $size }) => $size}rem;
  margin: 0 auto;
`

export { Grid, Row, Column, Clamp }
