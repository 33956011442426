import styled from "styled-components"

export default styled.footer`
  background-color: ${({ theme: { dark } }) => dark};
  text-align: center;
  color: ${({ theme: { light } }) => light};
  font-family: ${({ theme }) => theme.body};
  font-size: 0.875rem;
  padding: 0.875rem;
`
