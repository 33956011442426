import React from "react"
import styled from "styled-components"

import Image from "./image"
import { Heading } from "./type"

const PhotoTextLayout = ({ children, className, heading, image }) => (
  <section className={className}>
    <div className="photo-text__inner">
      <div className="photo-text__image-wrap">
        <Image src={image} />
      </div>
      <div className="photo-text__content-container">
        {heading && <Heading as="h2">{heading}</Heading>}
        {children}
      </div>
    </div>
  </section>
)

const PhotoTextSection = styled(PhotoTextLayout)`
  width: 100%;
  display: flex;
  justify-content: center;
  .photo-text__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    display: flex;
    flex-flow: column;
    padding: 0;
  }
  .photo-text__content-container {
    padding: 2rem;
    overflow: hidden;
  }
  .photo-text__content {
    margin-bottom: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.md}) {
    .photo-text__inner {
      flex-flow: ${({ imageOn }) => (imageOn === "right" ? "row-reverse nowrap" : "row nowrap")};
      align-items: center;
    }
    .photo-text__image-wrap {
      flex: 0 0 50%;
      margin: 2rem 0;
    }
    .photo-text__content-container {
      flex: 0 1 50%;
      padding: 2rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.lg}) {
    .photo-text__content-container {
      padding: 2rem 3rem;
    }
  }
`

export default PhotoTextSection
