import React from "react"
import styled from "styled-components"
import { Heading, Text } from "./type"

const Testimonial = ({ children, className, cite }) => (
  <blockquote className={className}>
    <Text>{children}</Text>
    <Heading as="cite" $size="1.75rem">
      {cite}
    </Heading>
  </blockquote>
)

export default styled(Testimonial)`
  font-family: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.gray};
  line-height: 1.5rem;
  margin: 1rem 0;

  ${Text} {
    text-align: left;
    padding: 0 1.75rem 0.25rem;
  }

  ${Heading} {
    display: block;
    font-style: normal;
    text-align: right;
    font-size: 1.125rem;
    padding-top: 0;
  }

  @media (min-width: ${({ theme }) => theme.md}) {
    margin: 1rem 1rem 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
`
