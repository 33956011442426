import React, { useContext } from "react"
import styled, { css, ThemeProvider, ThemeContext } from "styled-components"
import { inverted } from "./theme"

const Section = ({ children, className, gradient = false, invert = false }) => {
  const theme = useContext(ThemeContext)

  return (
    <section className={className}>
      <ThemeProvider theme={invert || gradient ? inverted : theme}>{children}</ThemeProvider>
    </section>
  )
}

export default styled(Section)`
  padding: 2rem 0;

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      @media (min-height: ${({ theme: { md } }) => md}) {
        height: 100vh;
      }
    `}
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${({ theme }) => theme.maxWidth}};
    `}
  ${({ center }) =>
    center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
  ${({ gradient }) =>
    gradient &&
    css`
      background: linear-gradient(180deg, ${({ theme: { dark } }) => dark} 0%, ${({ theme: { black } }) => black} 100%)
        0% 0% no-repeat;
    `}
`
