import styled, { css } from "styled-components"

const Heading = styled.h1`
  font-family: ${({ theme }) => theme.heading};
  font-weight: ${({ theme }) => theme.headingWeight};
  color: ${({ theme }) => theme.dark};

  text-align: center;
  line-height: 2.1875rem;
  width: 100%;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.md}) {
    line-height: 2.75rem;
    text-align: ${({ $center }) => ($center ? "center" : "left")};
  }
`

const Fancy = styled.span`
  font-style: italic;
  font-weight: 700;

  ${({ $color }) =>
    $color &&
    css`
      color: ${({ theme, $color }) => theme[$color]};
    `}
`

const Text = styled.p`
  font-family: ${({ theme }) => theme.body};
  font-weight: ${({ theme }) => theme.bodyWeight};
  font-size: ${({ $size }) => ($size ? $size : "1rem")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  text-transform: ${({ $transform }) => $transform};
  margin: 0;
  max-width: 50rem;
  color: ${({ theme }) => theme.dark};
  line-height: 1.5;

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `}
`

export { Heading, Text, Fancy }
