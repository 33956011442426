import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export default styled(BackgroundImage)`
  height: 300px;
  min-height: 100%;
  @media (min-width: ${({ theme }) => theme.md}) {
    height: 100vh;
  }
`
