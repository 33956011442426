import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "./image"

const Logo = (props) => {
  const { logo } = useStaticQuery(graphql`
    query Logo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 354) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return <Image src={logo} {...props} />
}

export default Logo
