import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { Row, Column } from "../components/layout"
import { Heading } from "./type"
import Button from "../components/button"
import { track } from "../tracking"
import Heart from "../icons/heart"
import InputMask from "react-input-mask"
import * as yup from "yup"

const Form = ({ heading, name }) => {
  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const formSchema = yup.object().shape({
    First: yup.string().required(),
    Last: yup.string().required(),
    Email: yup.string().email().required(),
    Phone: yup.string().required(),
    Message: yup.string(),
  })

  const [status, setStatus] = useState("initial")
  const { register, handleSubmit, formState, errors } = useForm({ validationSchema: formSchema })
  const { isDirty } = formState

  const [hasMounted, setMounted] = useState(false)
  const key = hasMounted ? "client" : "server"

  const First = register({ required: true, maxLength: 25 })
  const Last = register({ required: true, maxLength: 25 })
  const Email = register({ required: true, pattern: /^\S+@\S+$/i })
  const Phone = register({ required: true, maxLength: 14 })
  const Message = register()

  useEffect(() => {
    setMounted(true)
  }, [hasMounted])

  useEffect(() => {
    if (isDirty) track({ type: "begin_form" })
  }, [isDirty])

  const onSubmit = (values) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": name, ...values }),
    })
      .then(() => {
        setStatus("success")
        track({ type: "lead" })
      })
      .catch((error) => {
        setStatus("failed")
        console.error("Fetch Error: ", error)
        console.log("Form Field errors: ", errors)
      })
  }

  switch (status) {
    case "failed":
      return (
        <>
          <Heading as="h2">Submission Failed</Heading>
          <div className="status">
            An error has occurred. Please contact us at
            <a href="mailto:info@hmelawfirm.com"> info@hmelawfirm.com</a> or
            <a href="tel:5615678549"> (561) 567-8549</a>.
          </div>
          <Button icon="Phone" href="tel:5615678549">
            Call Us Now
          </Button>
        </>
      )
    case "success":
      return (
        <>
          <Heart />
          <Heading as="h2">Your Request has Been Received!</Heading>
          <div className="status">
            Thank you for requesting a consultation. Our team will process your request and reach out as soon as
            possible with more details.
          </div>
        </>
      )
    default:
      return (
        <>
          <Heading as="h2">{heading}</Heading>
          <form data-netlify="true" name={name} netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
            <label style={{ display: "none" }}>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
            <Row>
              <Column>
                <input
                  aria-invalid={errors.Last && true}
                  type="text"
                  name="First"
                  ref={First}
                  required={!hasMounted && true}
                  key={`${key}-First`}
                />
                <label className="label" htmlFor="First">
                  First
                </label>
              </Column>
              <Column>
                <input
                  aria-invalid={errors.Last && true}
                  type="text"
                  name="Last"
                  ref={Last}
                  required={!hasMounted && true}
                  key={`${key}-Last`}
                />
                <label htmlFor="Last">Last</label>
              </Column>
            </Row>
            <Row>
              <Column>
                <input
                  aria-invalid={errors.Email && true}
                  type="text"
                  name="Email"
                  ref={Email}
                  required={!hasMounted && true}
                  key={`${key}-Email`}
                />
                <label htmlFor="Email">Email</label>
              </Column>
              <Column>
                <InputMask
                  mask="(999) 999-9999"
                  aria-invalid={errors.Phone && true}
                  type="tel"
                  name="Phone"
                  inputRef={Phone}
                  required={!hasMounted && true}
                  key={`${key}-Phone`}
                />
                <label htmlFor="Phone">Phone</label>
              </Column>
            </Row>

            <Row>
              <Column>
                <textarea
                  name="Message"
                  id="message"
                  ref={Message}
                  required={!hasMounted && true}
                  key={`${key}-Message`}
                ></textarea>
                <label htmlFor="Message">Describe Your Case</label>
              </Column>
            </Row>
            <Row justify="center" align="center">
              <Button as="button" type="submit">
                LET US HELP
              </Button>
            </Row>
          </form>
        </>
      )
  }
}

const FormWrapper = ({ className }) => {
  return (
    <Row id="form" className={className} justify="center" align="center">
      <Form name="schedule-consultation" heading="Get a Free Personal Injury Consultation" />
    </Row>
  )
}

export default styled(FormWrapper)`
  flex-direction: column;
  font-family: ${({ theme }) => theme.body};
  background: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.light};
  transition: all 500ms ease-in;
  padding-bottom: 0 !important;

  #form& h2 {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.875rem;
    color: ${({ theme }) => theme.light};
    text-align: center;
    margin: 2.5rem 0 1rem;
  }

  form {
    width: calc(100% - 1rem);
  }

  .status {
    text-align: center;
  }

  ${Row} {
    margin-bottom: 0.5rem;
  }

  ${Column} {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    padding: 0 0.5rem;
    flex-flow: column-reverse;
    justify-content: center;
  }

  label {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.75rem;
    font-family: ${({ theme }) => theme.body};
  }

  input {
    padding: 0.5rem 0.75rem;
    border: 1px solid ${({ theme }) => theme.dark};
    border-radius: 0;
    width: 100%;
    height: 3rem;
    background: white;
  }

  textarea {
    width: 100%;
    min-height: 118px;
  }

  input[aria-invalid="true"] {
    border: 2px solid ${({ theme }) => theme.accent};
  }

  input[aria-invalid="true"] + label::after {
    content: "Required";
    float: right;
    font-weight: normal;
  }

  input[aria-invalid="true"] + label {
    color: ${({ theme }) => theme.accent};
    font-weight: 700;
  }

  ${Row}:last-child {
    padding-bottom: 0.5rem;
  }

  @media (min-width: ${({ theme }) => theme.lg}) {
    padding: 1.625rem;

    ${Column} {
      flex: 1;
      padding: 0 0.5rem;
    }
  }
`
