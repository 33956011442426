import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
                box-sizing: inherit;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 1.875rem;
    }

    a {
        color: ${({ theme }) => theme.accent};
    }

`
