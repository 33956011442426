import React from "react"
import { ThemeProvider } from "styled-components"

export const inverted = ({ light, dark, ...rest }) => ({
  light: dark,
  dark: light,
  ...rest,
})

export default ({ children }) => {
  const SANS_SERIF_FALLBACK =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

  return (
    <ThemeProvider
      theme={{
        heading: '"Cinzel", serif',
        headingWeight: 500,
        subHeading: `"Montserrat", ${SANS_SERIF_FALLBACK}`,
        subHeadingWeight: 400,
        body: `"Montserrat", ${SANS_SERIF_FALLBACK}`,
        bodyWeight: 400,
        md: "50rem",
        lg: "70rem",
        maxWidth: "1088px",
        light: "#FFF",
        dark: "#3F4450",
        black: "#383838",
        accent: "#BB9314",
      }}
    >
      {children}
    </ThemeProvider>
  )
}
