import React from "react"
import styled from "styled-components"

const Banner = ({ className, children, href }) => (
  <div className={className}>
    <a href={href} className="outbound-link">
      {children}
    </a>
  </div>
)

export default styled(Banner)`
  background: ${({ theme }) => theme.red};
  text-align: center;
  /* padding: 0.8125rem 0; // matches mock */
  padding: 0.875rem 0.25rem; // matches footer
  top: 0;
  left: 0;
  width: 100vw;

  a {
    font-size: 1.125rem;
    font-family: ${({ theme }) => theme.body};
    font-weight: 500;
    color: ${({ theme }) => theme.darkText};
  }
`
