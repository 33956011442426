import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Phone from "../icons/phone.svg"
import { track } from "../tracking"
import { darken, desaturate } from "polished"

const icons = {
  Phone,
}

const Btn = ({ className, children, icon, type, ...props }) => {
  const Icon = icons[icon]

  if (type === "focus_form") {
    return (
      <AnchorLink href="#form" onClick={(e) => track({ e, type })} className={className} {...props}>
        {icon && <Icon />}
        {children}
      </AnchorLink>
    )
  }

  return (
    <a
      role="button"
      tabIndex="0"
      onKeyUp={(e) => track({ e, type })}
      onClick={(e) => track({ e, type })}
      className={className}
      {...props}
    >
      {icon && <Icon />}
      {children}
    </a>
  )
}

export default styled(Btn)`
  display: inline-block;
  border: 3px solid ${({ theme }) => theme.accent};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.25rem;
  padding: 0.75rem 3rem;
  color: ${({ theme, $color }) => ($color ? $color : theme.light)};
  font-family: ${({ theme }) => theme.body};
  font-size: 1.125rem;
  font-weight: 400;
  background: ${({ theme, $color }) => ($color ? $color : theme.accent)};
  margin: 2rem auto;

  cursor: pointer;
  transition: all 300ms ease;

  svg {
    fill: ${({ theme, $color }) => ($color ? $color : theme.light)};
    margin-right: 0.75rem;
    margin-left: -0.5rem;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover,
  &:active {
    background-color: ${({ theme }) => darken("0.1", theme.accent)};
    border: 3px solid ${({ theme }) => darken("0.1", theme.accent)};

    color: ${({ theme }) => theme.light};
    svg {
      fill: ${({ theme }) => theme.light};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => desaturate("0.3", theme.accent)};
    border: 3px solid ${({ theme }) => desaturate("0.3", theme.accent)};
    cursor: not-allowed;
  }

  @media (min-width: ${({ theme }) => theme.md}) {
    display: inline-block;
    margin-right: 1rem;
    &:last-child {
      margin-right: auto;
    }
  }
`
