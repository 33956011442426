import React from "react"
import styled from "styled-components"

const Heart = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6rem" viewBox="0 0 61.001 61">
    <g transform="translate(-4.84 -957.405)">
      <path
        className={className}
        d="M35.373,957.405a2.031,2.031,0,0,0-2.009,2.074v9.482a2.03,2.03,0,1,0,4.06,0v-9.482a2.031,2.031,0,0,0-2.051-2.074ZM11.481,961.49a2.033,2.033,0,0,0-1.205,3.471l7.442,7.45a2.031,2.031,0,1,0,2.875-2.857l-7.442-7.45a2.028,2.028,0,0,0-1.67-.614Zm47.361,0a2.029,2.029,0,0,0-1.205.614l-7.442,7.45a2.031,2.031,0,1,0,2.875,2.857l7.442-7.45a2.032,2.032,0,0,0-1.67-3.471ZM28.354,973.025a8.787,8.787,0,0,0-5.307,1.63,6.708,6.708,0,0,0-2.41,4.233c-.532,3.19.551,6.622,2.135,10.011a42.853,42.853,0,0,0,5.92,9.228,22.644,22.644,0,0,0,3.193,3.175,5.412,5.412,0,0,0,3.51,1.524,5.412,5.412,0,0,0,3.51-1.524,22.644,22.644,0,0,0,3.193-3.175,42.857,42.857,0,0,0,5.92-9.228c1.585-3.389,2.667-6.821,2.135-10.011a6.708,6.708,0,0,0-2.41-4.233,8.787,8.787,0,0,0-5.307-1.63h-.063a11.358,11.358,0,0,0-6.977,2.773,11.359,11.359,0,0,0-6.977-2.773h-.063Zm.042,4.064h.063c1.855.032,3.587,1.051,5.582,2.857a2.028,2.028,0,0,0,2.706,0c1.994-1.806,3.726-2.825,5.582-2.857h.063a4.86,4.86,0,0,1,2.854.762,2.416,2.416,0,0,1,.909,1.693c.278,1.667-.414,4.638-1.818,7.641A39.373,39.373,0,0,1,39.01,995.5a18.974,18.974,0,0,1-2.6,2.582,5.248,5.248,0,0,1-1.015.614,5.247,5.247,0,0,1-1.015-.614,18.975,18.975,0,0,1-2.6-2.582,39.374,39.374,0,0,1-5.328-8.318c-1.4-3-2.1-5.974-1.818-7.641a2.416,2.416,0,0,1,.909-1.693,4.859,4.859,0,0,1,2.854-.762Zm-21.629,8.8a2.035,2.035,0,0,0,.211,4.064H16.45a2.032,2.032,0,0,0,0-4.064H6.978a2.036,2.036,0,0,0-.211,0Zm47.361,0a2.035,2.035,0,0,0,.211,4.064H63.81a2.032,2.032,0,0,0,0-4.064H54.338A2.036,2.036,0,0,0,54.127,985.894Zm-35.2,16.911a2.029,2.029,0,0,0-1.205.614l-7.442,7.45a2.031,2.031,0,1,0,2.875,2.857l7.442-7.45a2.032,2.032,0,0,0-1.67-3.471Zm32.476,0a2.033,2.033,0,0,0-1.205,3.471l7.442,7.45a2.031,2.031,0,1,0,2.875-2.857l-7.442-7.45a2.028,2.028,0,0,0-1.67-.614Zm-16.026,2.011a2.031,2.031,0,0,0-2.009,2.074v9.482a2.03,2.03,0,1,0,4.06,0v-9.482a2.031,2.031,0,0,0-2.051-2.074Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
)

export default styled(Heart)`
  fill: ${({ theme }) => theme.accent};
`
