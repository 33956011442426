import React from "react"
import styled from "styled-components"
import Image from "./image"
import { Heading, Text } from "./type"

const Card = ({ className, image, heading, text }) => (
  <div className={className}>
    <Image src={image} />
    <Heading as="h4" $size="1.625rem">
      {heading}
    </Heading>
    <Text>{text}</Text>
  </div>
)

export default styled(Card)`
  ${Heading}, ${Text} {
    text-align: center;
    margin: 0 auto;
  }

  ${Image} {
    display: block !important;
    margin: 0.5rem auto;
  }
  @media (min-width: ${({ theme }) => theme.md}) {
    padding: 1rem;
  }
`
