function log(message) {
  const logPrefix = "Event Tracking"
  return console.log(`${logPrefix}: ${message}`)
}

/**
 * Determine if a function is present on global object (window or globalThis)
 *
 * @param  {...string} trackFns Any number of functions names to check
 */
const missing = (...trackFns) => trackFns.map((fn) => window[fn] === undefined).includes(true)

export const track = ({ e, type, value = 1 }) => {
  if (process.env.NODE_ENV === "development") {
    return log(`Tracking disabled in dev environment (would have fired tracking for type: ${type})`)
  }

  const remarketingID = "AW-496749262"

  /*eslint-disable*/
  switch (type) {
    case "focus_form":
      if (!missing("gtag")) { gtag("event", type) }
      if (!missing("fbq")) { fbq("trackCustom", type) }
      let simplifiButtonScript = document.createElement('script');
      simplifiButtonScript.setAttribute('src', 'https://tag.simpli.fi/sifitag/88710870-85fa-013a-53d4-06abc14c0bc6');
      simplifiButtonScript.setAttribute('async', true)
      document.body.appendChild(simplifiButtonScript);
      break
    case "begin_form":
      if (!missing("gtag")) { gtag("event", type) }
      if (!missing("fbq")) { fbq("trackCustom", type) }
      break
    case "click_to_call":
      if (!missing("gtag")) {  gtag("event", type, {
        value,
        currency: "USD",
        number: e.target.href.replace("tel:", ""),
      }) }
      if (!missing("gtag")) {  gtag("event", "conversion", {
        send_to: `${remarketingID}/Ug8eCKWMp-0BEM6V7-wB`,
      }) }
      if (!missing("fbq")) { fbq("track", "Contact") }
      break
    case "lead":
      if (!missing("gtag")) {  gtag("event", "generate_lead", {
        value,
        currency: "USD",
      }) }
      if (!missing("gtag")) {  gtag("event", "conversion", {
        send_to: `${remarketingID}/AKdJCPuYlu0BEM6V7-wB`,
      }) } 
      if (!missing("fbq")) { fbq("track", "Lead") }
      let simplifiSubmitScript = document.createElement('script');
      simplifiSubmitScript.setAttribute('src', 'https://tag.simpli.fi/sifitag/2bccadc0-85fc-013a-c608-06a60fe5fe77');
      simplifiSubmitScript.setAttribute('async', true)
      document.body.appendChild(simplifiSubmitScript);
      break
    default:
      log("An event type must be provided!")
      break
  }
  /*eslint-enable*/
}
