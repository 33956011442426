import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { transparentize } from "polished"
import Image from "./image"
import Bg from "./bg"
import Section from "./section"
import { Row, Column } from "./layout"
import Form from "./form"
import { Heading, Text } from "./type"
import Logo from "./logo"
import Button from "./button"
import useWindowSize from "../useWindowSize"

const Background = styled(Bg)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Subheading = styled(Text)`
  font-weight: 500;
  font-size: 1.375rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
`

function Hero({ bg, className }) {
  const { width } = useWindowSize()
  const { maxWidth } = useContext(ThemeContext)
  const isMobile = width <= maxWidth.replace("px", "")

  if (isMobile) {
    return (
      <>
        <Row className={`${className} mobile`}>
          <Image src={bg} />
          <Column justify="center" className="hero">
            <Logo className="logo" />
            <Heading>Were you injured due to someone else’s fault?</Heading>
            <Subheading>Let Our Team Fight To Get You Justice</Subheading>
            <Text>
              For over 30 years, the legal team here at Hicks & Motto Attorneys At Law have been
              <strong> fighting to obtain justice</strong> for people all over our great state. With a focus on personal
              injury law, we have helped our clients obtain <strong>over $500 million dollars</strong> in compensation
              due to the negligence of others. Best of all,
              <strong> if we don’t obtain any money for you regarding your claim, you don’t owe us any money.</strong>
            </Text>
            {isMobile && (
              <Button type="click_to_call" icon="Phone" href="tel:5615678549">
                Call Us Today
              </Button>
            )}
          </Column>
          <Form />
        </Row>
      </>
    )
  }

  return (
    <Background className={`${className} desktop`} fluid={bg.childImageSharp.fluid}>
      <Section as="div" maxWidth center>
        <Row>
          <Column style={{ flex: "0 0 60%", padding: "3rem" }} justify="center" className="hero">
            <Logo className="logo" />
            <Heading>Were you injured due to someone else’s fault?</Heading>
            <Subheading>Let Our Team Fight To Get You Justice</Subheading>
            <Text>
              For over 30 years, the legal team here at Hicks & Motto Attorneys At Law have been
              <strong> fighting to obtain justice</strong> for people all over our great state. With a focus on personal
              injury law, we have helped our clients obtain <strong>over $500 millions dollars</strong> in compensation
              due to the negligence of others. Best of all,
              <strong> if we don’t obtain any money for you regarding your claim, you don’t owe us any money.</strong>
            </Text>
            {isMobile && (
              <Button type="click_to_call" icon="Phone" href="tel:5615678549">
                Call Us Today
              </Button>
            )}
          </Column>
          <Form />
        </Row>
      </Section>
    </Background>
  )
}

export default styled(Hero)`
  ${Heading} {
    margin: 0.5rem 0;
    font-size: 2.185rem;
    font-weight: 500;
    line-height: 2.25rem;
  }

  &.mobile {
    ${Subheading} {
      text-align: center;
      font-size: 1.185rem;
    }
    ${Text} {
      margin: 0 auto;
    }
    .logo {
      align-self: center;
      min-width: 243px;
    }
  }

  &.desktop {
    .hero {
      background: ${({ theme }) => transparentize(".3", theme.light)};
    }

    .logo {
      max-width: 354px;
    }

    ${Text} {
      max-width: 545px;
    }
  }
`
