import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Image = ({ src, ...props }) => {
  if (!src?.childImageSharp) {
    return <div>Picture not found</div>
  }

  if (src.childImageSharp.fixed) {
    return <Img {...props} fixed={src.childImageSharp.fixed} />
  } else {
    return <Img {...props} fluid={src.childImageSharp.fluid} />
  }
}

export default styled(Image)``
